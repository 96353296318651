$white: #fff;
$black: #000;
$background-line: #ff969333;
$members-card-border: #ff96937d;
$primary-red: #e5322d;
$background: #fff;
$text: #303548;
$primary-blue: #0f64fa;
$contact-gray: #f3f3f3;
$about-color: #464c55;
$dark-border: #242424;
$dark-text: #434343;
$member-gray:#c4c4c4
