@import "../../../colors.scss";

.secondary-button-styles {
  border-radius: 10px;
  width: max-content;
  padding: 12px 22px;
  font-family: "Lato";
  font-size: 400;
  text-transform: uppercase;
  display: flex;
  font-size: 16px;
  color: $primary-red;
  border: 2px solid $dark-border;

  .icon {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 480px) {
  .secondary-button-styles {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 700;
    min-width: max-content;
    width: auto;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .secondary-button-styles {
    font-size: 30px;
    line-height: 36px;
    text-transform: capitalize;
    font-weight: 700;
    min-width: max-content;
    width: auto;

    .icon {
      height: 28px;
      width: 28px;
    }
  }
}

@media screen and (min-width: 1919px) {
  .secondary-button-styles {
    border-radius: 10px;
    font-size: 30px;
    line-height: 36px;
    border: 4px solid #242424;
    padding: 24px 33px;

    .icon {
      width: 25px;
      height: 25px;
      margin-left: 27px;
    }
  }
}