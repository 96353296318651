@import "../../colors.scss";

.memberCard {
  background: $white;
  width: 100%;
  border: 1px solid $members-card-border;
  width: 90%;
  margin: auto;
  border-radius: 30px;
  position: relative;

  .arrow-div-1 {
    padding: 10px 20px;
    border: 0.5px solid $primary-blue;
    margin-right: 10px;
    width: max-content;
    background-color: $white;

    img {
      height: 18px;
      align-self: center;
    }

    position: absolute;
    top: 50%;
    left: -30px;
  }

  .arrow-div-2 {
    padding: 10px 20px;
    border: 0.5px solid $primary-blue;
    margin-left: 10px;
    width: max-content;
    position: absolute;
    top: 50%;
    right: -30px;
    background-color: $white;

    img {
      height: 18px;
      align-self: center;
      transform: rotate(180deg);
    }
  }

  .inner-content {
    width: 75%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;

    .img-div {
      height: 170px;
      width: 170px;
      border-radius: 50%;
      background-color: $member-gray;
      border: 1px solid $background-line;
      margin: auto;
    }

    .member-name {
      font-family: "Lato";
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 45px;
      text-align: center;
      color: $primary-red;
      margin-top: 10px;
    }

    .designation {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 50px;
      text-align: center;
      color: $dark-text;
    }

    .quote {
      font-family: "Lato";
      font-style: italic;
      font-weight: 500;
      font-size: 24px;
      line-height: 45px;
      text-align: center;
      color: $dark-text;
      margin-top: 5%;
      margin-bottom: 5%;
    }

    .about-text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 45px;
      text-align: center;
      color: $dark-text;
    }

    .read-more {
      font-family: "Lato";
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 50px;
      text-align: center;
      color: $primary-blue;
      margin-top: 2%;
    }
  }
}