.serviceCard {
  width: 100%;
  background: #f3f3f3;
  border-radius: 8px;
  padding-top: 19px;
  padding-bottom: 28px;

  .border {
    border: 0.5px solid #30354870;
  }
  .service-Card-mini {
    background-color: #fff;
    display: flex;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 50px;
    color: #303548;
    border-radius: 6px;
    margin-right: 29px;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    margin-bottom: 1px;
    margin-left: 1px;
    cursor: pointer;
    .div-img {
      height: 40px;
      width: 40px;
      position: relative;
      display: flex;
      justify-content: center;
      margin-left: 24px;
      margin-top: 5px;
      border-radius: 8px;
      margin-right: 25px;

      img {
        height: 28px;
        width: 20px;
        align-self: center;
      }
      .img-stye {
        height: 20px;
        width: 20px;
      }
    }
    .box-shadow {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
  }
  .header-text {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 50px;
    color: #303548;
    margin-left: 24px;
  }
  .service-Card-mini:hover > .div-img {
    box-shadow: 0px 2px 2px rgba(229, 50, 45, 0.25);
  }
  .guide-Card-mini {
    background-color: #fff;
    display: flex;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #303548;
    border-radius: 6px;
    margin-right: 29px;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    margin-bottom: 1px;
    padding-top: 5px;
    padding-bottom: 8px;
    margin-left: 1px;
    cursor: pointer;
    padding-right: 10px;
    div {
      align-self: center;
    }

    .div-img {
      height: 40px;
      width: 40px;
      position: relative;
      display: flex;
      justify-content: center;
      margin-left: 24px;
      margin-top: 5px;
      border-radius: 8px;
      margin-right: 25px;
      img {
        height: 40px;
        width: 40px;
        align-self: center;
      }
    }
  }
}
@media screen and (min-width: 1919px) {
  .serviceCard {
    width: 604px;
    background: #f3f3f3;
    border-radius: 10px;
    padding-top: 19px;
    padding-bottom: 28px;

    .service-Card-mini {
      width: 575px;
      height: 99px;
      font-size: 30px;
      line-height: 50px;
      align-items: center;
      .div-img {
        height: 70px;
        width: 70px;
        border-radius: 10px;

        img {
          height: 45px;
          width: 33px;
          align-self: center;
        }
        .img-stye {
          height: 20px;
          width: 20px;
        }
      }
      .box-shadow {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      }
    }
    .header-text {
      font-size: 30px;
      line-height: 50px;
      margin-top: 26px;
      margin-bottom: 25px;
    }
    .service-Card-mini:hover > .div-img {
      box-shadow: 0px 2px 2px rgba(229, 50, 45, 0.25);
    }
    .guide-Card-mini {
      width: 575px;
      height: 99px;
      font-size: 30px;
      line-height: 50px;
      border-radius: 10px;
      margin-bottom: 4px;
      div {
        align-self: center;
      }

      .div-img {
        height: 70px;
        width: 70px;
        position: relative;
        display: flex;
        justify-content: center;
        margin-left: 24px;
        margin-top: 5px;
        border-radius: 8px;
        margin-right: 25px;
        img {
          height: 70px;
          width: 70px;
          align-self: center;
        }
      }
    }
  }
}
