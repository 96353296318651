@import "../../colors.scss";

.contactComponent {
  width: 62%;
  margin: 9% 17%;
  margin-bottom: 10vh;

  .contact-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    color: $primary-red;
    text-align: center;
  }

  .contact-content {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: $text;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
  }

  .contact-content-container {
    display: flex;
    width: 100%;
    margin-top: 3vh;

    .left-content {

      .wa {
        display: flex;
        width: max-content;
        margin: auto;

        .ph {
          margin-right: 20px;
        }

        .wapp {
          margin-left: 20px;
        }

        img {
          height: 40px;
          width: 40px;
          margin: 10px;
        }
      }

      .get-in-touch {
        color: $primary-red;
        display: flex;
        justify-content: center;
        font-family: "Lato";
        font-size: 25px;
        letter-spacing: 0.6em;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 80px;
        margin-left: 20px;
      }

      .contct-m {
        text-align: center;
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: 10vh;
      }

      .contct {
        display: grid;
        grid-template-columns: auto;
        border: 1px solid $primary-blue;
        width: max-content;
        margin: auto;
        padding: 30px 50px 0px 50px;
        margin-top: 20px;

        .contct-c {
          text-align: center;
          margin: auto;
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
        }
      }
    }

    .right-content {
      margin-left: 20px;
      padding-top: 9%;
      padding-bottom: 0;
      margin-right: 0px;
      margin-left: auto;
      width: 50%;
    }
  }

  .btn-cont {
    display: flex;
    justify-content: center;
    margin: 2%;

    a {
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .contactComponent {
    width: 100%;
    padding: 0;
    padding-top: 5%;
    margin: 0;

    .contact-header {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 120px;
      text-align: center;
    }

    .contact-content {
      font-size: 15px;
      line-height: 30px;
      margin-top: -5%;
    }

    .contact-content-container {
      display: block;

      .left-content {
        width: 100%;

        .get-in-touch {
          font-size: 15px;
          letter-spacing: 0.4em;
          margin-bottom: 0;
          text-align: center;
          margin-left: 0;
          margin-top: 60px;
          margin-bottom: 5%;
          line-height: 45px;
        }

        .contct {
          grid-template-columns: auto;
          width: 80%;
          margin: auto;
          border: none;
          padding: 0;
          margin-top: 19px;
        }
      }

      .right-content {
        width: 90%;
        margin-left: 0;
        margin: auto;
        margin-top: 10%;
      }
    }

    .btn-cont {
      margin: 5%;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .contactComponent {
    width: 100%;
    padding: 0;
    padding-top: 5%;
    margin: 9% 0%;

    .contact-header {
      font-size: 45px;
      line-height: 120px;
    }

    .contact-content {
      font-size: 20px;
      line-height: 35px;
      margin-top: 0;
    }

    .contact-content-container {
      display: block;

      .left-content {
        width: 100%;

        .get-in-touch {
          display: flex;
          justify-content: flex-start;
          padding-left: 8%;
          font-size: 25px;
          line-height: 45px;
          letter-spacing: 0.7em;
          margin-bottom: 4%;
        }

        .contct-m {
          margin-top: 0;
        }

        .contct {
          grid-template-columns: auto auto;
          width: 70%;
          margin: auto;
          border: none;
          padding: 0;

          .contct-c {
            margin-top: 20%;
          }
        }
      }

      .right-content {
        width: 60%;
        margin-left: 0;
        margin: auto;
        margin-top: 10%;
        padding-top: 0;
      }
    }

    .btn-cont {
      margin: 5%;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .contactComponent {
    width: 100%;
    padding-left: 0;
    padding-bottom: 10%;

    .contact-content-container {
      .left-content {
        padding-left: 5%;

      }
    }
  }
}

@media screen and (min-width: 1919px) {
  .contactComponent {
    width: 90%;
    padding-left: 5vh;

    .contact-header {
      font-size: 60px;
      line-height: 120px;
    }

    .contact-content {
      font-size: 30px;
      line-height: 45px;
    }

    .contact-content-container {
      display: flex;
      width: 100%;
      margin-top: 5%;

      .left-content {
        width: 50%;

        .wa {
          display: flex;
          width: max-content;
          margin: auto;

          img {
            height: 44px;
            width: 44px;
            margin: 14px;
          }
        }

        .get-in-touch {
          font-size: 30px;
          line-height: 45px;
          letter-spacing: 1em;

        }

        .contct-m {
          margin-top: 180px;
        }

      }

    }

  }
}