@import "../../../colors.scss";

.mobileMemberCard {
  .inner-mobile-Card {
    background: $white;
    width: 100%;
    border: 1px solid $background-line;
    width: 90%;
    margin: auto;
    border-radius: 30px;
    position: relative;

    .inner-content {
      width: 90%;
      margin: auto;
      margin-top: 10%;
      margin-bottom: 10%;

      .img-container {
        display: flex;
        flex: 1;

        .img-div {
          height: 100px;
          width: 100px;
          border-radius: 50%;
          margin: auto;
          flex: 1;
        }

        .cont {
          display: block;
          padding-left: 10%;
          flex: 2;

          .member-name {
            font-family: "Lato";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 45px;
            color: $primary-red;
          }

          .designation {
            font-family: "Lato";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $dark-text;
          }
        }

      }

      .quote {
        font-family: "Lato";
        font-style: italic;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: $dark-text;
        margin-top: 5%;
        margin-bottom: 5%;
      }

      .about-text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        color: $dark-text;
      }

      .read-more {
        font-family: "Lato";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        color: $primary-blue;
        margin-top: 2%;
      }
    }
  }

  .arrow-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10%;

    .arrow-div-1 {
      padding: 15px 30px;
      border: 0.5px solid $primary-blue;
      margin-right: 10px;

      img {
        height: 18px;
        align-self: center;
      }
    }

    .arrow-div-2 {
      padding: 15px 30px;
      border: 0.5px solid $primary-blue;
      margin-left: 10px;

      img {
        height: 18px;
        align-self: center;
        transform: rotate(180deg);
      }
    }
  }
}