@import ".././../colors.scss";

.contactForm {
  background: $contact-gray;
  border-radius: 40px;
  padding: 4vh;

  .contact-form-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #303548;
    text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(50px);
  }

  .form {
    margin-top: 25px;

    .input-container {
      margin-bottom: 20px;

      .label {
        font-family: "Lato";
        font-weight: 700;
        font-size: 14px;
        color: rgba(48, 53, 72, 0.75);
      }

      .input {
        background: $white;
        border-radius: 8px;
        width: 100%;
        height: 40px;
        border: none;
        outline: none !important;
        margin-top: 10px;
      }

      select {
        cursor: pointer;
      }

      .phone-input {
        display: flex;
        margin-top: 10px;

        .input-1 {
          background: $white;
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
          width: 20%;
          height: 40px;
          outline: none !important;
          border-right: 0.5px solid #303548bf;
          border-left: 1px solid $white;
          border-top: 1px solid $white;
          border-bottom: 1px solid $white;
          padding: 0 10px;
        }

        .input-2 {
          background: $white;
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
          width: 80%;
          height: 40px;
          outline: none !important;
          border: none;
          padding: 0 10px;
        }
      }
    }

    .radio-container {
      display: flex;
      width: 100%;

      .label {
        width: 33%;
        color: $text;
        font-family: "Lato";
        font-weight: 700;
        font-size: 14px;
        color: rgba(48, 53, 72, 0.75);
      }

      .radio-cont {
        display: block;
        width: 33%;
        color: $text;
        font-family: "Lato";
        font-weight: 400;
        font-size: 14px;
        color: rgba(48, 53, 72, 0.75);

        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 14px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .container input {
          position: absolute;
          //   opacity: 0;
          cursor: pointer;
          left: 0;
        }
      }
    }

    .custom-select {
      background: $white;
      border-radius: 8px;
      width: 95%;
      height: 40px;
      padding-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-right: 10px;
      display: flex;
      position: relative;

      select {
        background: transparent;
        border-radius: 8px;
        width: 100%;
        height: 40px;
        border: none;
        outline: none !important;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-family: "Lato";
        font-weight: 700;
        font-size: 14px;
        color: rgba(48, 53, 72, 0.75);
        cursor: pointer;
        z-index: 1;
      }

      img {
        height: 15px;
        align-self: center;
        margin-right: 10px;
        position: absolute;
        right: 0;
      }
    }

    .contact-btn {
      background: linear-gradient(90.16deg,
          rgba(255, 255, 255, 0.2) 0.93%,
          rgba(98, 134, 205, 0.153326) 47.11%,
          rgba(255, 255, 255, 0.1) 99.86%);
      border: 2px solid #303548;
      box-shadow: 0px 4px 10px rgba(145, 120, 120, 0.5);
      backdrop-filter: blur(60px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 30px;
      width: max-content;
      padding: 15px 40px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      margin: auto;
      margin-top: 5vh;
      display: flex;
      justify-content: center;
      cursor: pointer;
      width: 130px;
    }
  }

  .loader {
    border: 2px solid #f3f3f3;
    /* Light grey */
    border-top: 2px solid #303548;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@media screen and (max-width: 480px) {
  .contactForm {
    .contact-form-header {
      font-size: 15px;
    }

    .form {
      .input-container {
        .phone-input {
          .input-1 {
            width: 30%;
          }
        }
      }

      .radio-container {
        .radio-cont {
          .container {
            padding-left: 6vw;
          }
        }

        .label {
          width: 30%;
        }
      }
    }
  }
}

@media screen and(min-width:481px) and (max-width: 800px) {
  .contactForm {
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and(min-width:801px) and (max-width: 1024px) {
  .contactForm {
    width: 45vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1919px) {
  .contactForm {
    border-radius: 68px;
    width: 624px;
    height: 940px;
    padding: 55px;

    .contact-form-header {
      font-size: 30px;
      line-height: 45px;
    }

    .form {
      .input-container {
        margin-bottom: 0;

        .label {
          font-size: 20px;
          line-height: 80px;
        }

        .input {
          height: 60.95px;
        }

        .phone-input {
          display: flex;
          margin-top: 10px;

          .input-1 {
            height: 60.95px;
          }

          .input-2 {
            height: 65px;
          }
        }
      }

      .radio-container {
        display: flex;
        width: 100%;
        margin-top: 30px;

        .label {
          font-size: 20px;
          line-height: 80px;
        }

        .radio-cont {
          display: block;
          width: 33%;
          color: $text;
          font-family: "Lato";
          font-weight: 400;
          font-size: 14px;
          color: rgba(48, 53, 72, 0.75);
          align-self: center;

          .container input {
            width: 30px;
            height: 30px;
            margin-top: -3px;
          }
        }
      }

      .custom-select {
        height: 70px;

        select {
          height: 70px;
        }

        img {
          height: 23px;
          align-self: center;
          margin-right: 10px;
        }
      }

      .contact-btn {
        width: max-content;
        padding: 3px 87px;
        border-radius: 40px;
        font-size: 30px;
        line-height: 80px;
        margin-bottom: 73px;
      }
    }
  }
}