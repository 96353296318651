@import "../../colors.scss";
.ClientCard {
  display: block;
  height: max-content;
  padding: 40px;
  .cont-dv {
    .client-name {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      color: $primary-blue;
    }
    .client-rev {
      font-family: "Lato";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      color: $text;
      text-align: justify;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 3vh;
      //height: 18vh
    }
    .client-des {
      font-family: "Lato";
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      color: $text;
      text-align: justify;
      line-height: 20px;
      margin-top: 1vh;
    }
  }

  .play {
    color: $primary-red;
    font-size: 18px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    display: flex;
    margin-top: 5vh;
    text-decoration: none;
    border: 1px solid $primary-red;
    width: max-content;
    padding: 12px 41px;
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;
    img {
      height: 16px;
      width: 16px;
      align-self: center;
      margin-right: 10px;
    }
  }
}
@media screen and (max-width: 480px) {
  .ClientCard {
    .cont-dv {
      height: max-content;
      .client-name {
        text-align: center;
        font-size: 15px;
        line-height: 45px;
        letter-spacing: 0.3em;
      }
      .client-rev {
        text-align: center;
        font-size: 15px;
        line-height: 35px;
      }
      .client-des {
        text-align: center;
      }
    }
    .play {
      margin: auto;
      font-size: 15px;
      line-height: 18px;
      margin-top: 5vh;
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 1024px) {
  .ClientCard {
    width: 90%;
    padding: 40px 55px 40px 30px;
    .cont-dv {
      .client-name {
        text-align: center;
        font-size: 20px;
        line-height: 45px;
        text-align: center;
        letter-spacing: 0.3em;
      }
      .client-rev {
        text-align: center;
        font-size: 25px;
        line-height: 35px;
      }
      .client-des {
        text-align: center;
        font-size: 16px;
      }
    }
    .play {
      margin: auto;
      margin-top: 8vh;
    }
  }
}
@media screen and (min-width: 1919px){
  .ClientCard {
    padding: 45px;
    width: 85%;
    .cont-dv {
      height: 479px;
      .client-name {
        font-size: 30px;
        line-height: 45px;
        letter-spacing: 0.3em;
      }
      .client-rev {
        font-size: 30px;
line-height: 45px;
      }
      .client-des {
        font-size: 15px;
        line-height: 40px;
        margin-top: 2vh;
      }
    }
  
    .play {
      font-size: 30px;
      line-height: 36px;
      padding: 24px 61px;
      border-radius: 600px;
      img {
        height: 23px;
        width: 23px;
        margin-right: 19px;
      }
    }
  }
}
