.modal-container {
  .modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100vh;
    /* Full height */
    overflow: hidden;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    overflow-x: hidden;
  }

  .modal-content {
    width: max-content;
    position: fixed;
    z-index: 1;
    margin: auto;
    left: 0;
    right: 0;
    top: 8%;
    animation: popout 1s ease;
    -webkit-animation: popout 1s ease;

    img {
      margin-top: -3vh;
      height: 90vh;
    }
  }

  @keyframes popout {
    from {
      transform: scale(0);
    }

    80% {
      transform: scale(1);
    }

    to {
      transform: scale(1);
    }
  }

  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }

    80% {
      -webkit-transform: scale(1);
    }

    to {
      -webkit-transform: scale(1);
    }
  }
}

@media screen and (max-width: 480px) {
  .modal-container {
    .modal-content {
      top: 20%;

      img {
        height: auto;
        width: 90vw;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .modal-container {
    .modal-content {
      img {
        width: 70vw;
      }
    }
  }
}