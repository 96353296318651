.Collapsible {
  width: 100%;
  margin-bottom: 30px;

  .border {
    border: 0.5px solid #30354870;
  }

  .main-container-col {
    .main-cont {
      background: #f3f3f3;
      border-radius: 3px;
      margin-bottom: 2px;
      padding: 3px 8px 3px 8px;

      .sing-cont {
        display: flex;
        flex: 1;

        .header-text {
          flex: 9;
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          color: #303548;
          margin-left: 10px;
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .arrow {
          flex: 1;
          width: 16px;
          height: 16px;
          display: flex;
          align-self: center;

          img {
            height: 16px;
            width: 16px;
          }
        }

        .service-Card-mini {
          background-color: #fff;
          display: flex;
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #303548;
          border-radius: 3px;
          box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
          cursor: pointer;
          padding-top: 8px;
          padding-bottom: 5px;
          width: 100%;

          .div-img {
            height: 30px;
            width: 30px;
            position: relative;
            display: flex;
            justify-content: center;
            margin-left: 24px;
            border-radius: 8px;
            margin-right: 25px;
            margin-top: -6px;

            img {
              width: 16.5px;
              height: 21px;
              align-self: center;
            }

            .img-stye {
              height: 20px;
              width: 20px;
            }
          }

          .box-shadow {
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          }
        }

        .service-Card-mini:hover>.div-img {
          box-shadow: 0px 2px 2px rgba(229, 50, 45, 0.25);
        }
      }

      .bottm-guide {
        display: block;
        width: 100%;

        .guide-Card-mini {
          background-color: #fff;
          display: flex;
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #303548;
          border-radius: 3px;
          box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
          cursor: pointer;
          padding-top: 5px;
          padding-bottom: 8px;
          margin-bottom: 3px;
          width: 100%;

          div {
            align-self: center;
          }

          .div-img {
            height: 27px;
            width: 27px;
            position: relative;
            display: flex;
            justify-content: center;
            margin-left: 4px;
            margin-top: 5px;
            border-radius: 8px;
            margin-right: 25px;

            img {
              height: 100%;
              width: 100%;
              align-self: center;
            }
          }
        }
      }

      .botm-cont {
        .s-Card-mini {
          background-color: #fff;
          display: flex;
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #303548;
          border-radius: 3px;
          box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
          cursor: pointer;
          padding-top: 8px;
          padding-bottom: 5px;
          width: 100%;
          margin-bottom: 5px;

          .div-img {
            height: 30px;
            width: 30px;
            position: relative;
            display: flex;
            justify-content: center;
            margin-left: 24px;
            border-radius: 8px;
            margin-right: 25px;
            margin-top: -6px;

            img {
              width: 16.5px;
              height: 21px;
              align-self: center;
            }

            .img-stye {
              height: 20px;
              width: 20px;
            }
          }

          .box-shadow {
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          }
        }

        .s-Card-mini:hover>.div-img {
          box-shadow: 0px 2px 2px rgba(229, 50, 45, 0.25);
        }
      }
    }

    .content-container {
      width: 100%;
      height: 392px;
      overflow-y: auto;
      background: #f3f3f381;
      margin-bottom: 10px;
      mask-image: linear-gradient(to bottom,
          black calc(100% - 48px),
          transparent 100%);

      .content {
        width: 90%;
        margin: auto;
        padding-bottom: 10%;

        .topic {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 19px;
          line-height: 25px;
          color: #303548;
          margin-bottom: 8px;
        }

        .margin-top {
          margin-top: 15px;
        }

        .margin-top-40 {
          margin-top: 25px;
        }

        .margin-top-60 {
          margin-top: 35px;
        }

        .content-text {
          font-family: "Lato";
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 25px;
          text-align: justify;
          color: #434343;

          img {
            width: 100%;
            margin-top: 20px;
          }
        }

        .bold-content {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and(min-width:481px) and (max-width: 1024px) {
  .Collapsible {
    margin-bottom: 5vh;

    .main-container-col {
      .content-container {
        height: 500px;

        .content {
          padding-top: 10px;

          .topic {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 35px;
            color: #303548;
            margin-bottom: 8px;
          }

          .margin-top {
            margin-top: 15px;
          }

          .margin-top-40 {
            margin-top: 25px;
          }

          .margin-top-60 {
            margin-top: 35px;
          }

          .content-text {
            font-size: 20px;
            line-height: 35px;

            img {
              width: 100%;
              margin-top: 20px;
            }
          }

          .bold-content {
            font-weight: 600;
          }
        }
      }

      .main-cont {
        padding: 10px 15px 10px 15px;

        .sing-cont {
          .header-text {
            font-size: 20px;
            line-height: 20px;
          }

          .arrow {
            height: 21px;
            width: 21px;

            img {
              height: 21px;
              width: 21px;
            }
          }

          .service-Card-mini {
            font-size: 20px;
            line-height: 20px;
            align-items: center;
            border-radius: 10px;
            height: 60px;

            .div-img {
              height: 50px;
              width: 50px;

              img {
                height: 28px;
                width: 22px;
              }
            }
          }
        }

        .bottm-guide {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 2vw;
          row-gap: 11px;
          margin-top: 18px;
          padding-right: 15px;

          .guide-Card-mini {
            font-size: 20px;
            line-height: 23px;
            border-radius: 10px;

            div:nth-child(2) {
              padding-right: 10px;
            }

            .div-img {
              height: 45px;
              width: 45px;
              margin-left: 17px;

              img {
                height: 45px;
                width: 45px;
              }
            }
          }
        }

        .botm-cont {
          display: flex;
          column-gap: 25px;
          margin-top: 18px;

          .s-Card-mini {
            font-size: 20px;
            width: 100%;
            border-radius: 10px;
            padding-top: 20px;
            padding-bottom: 14px;
            padding-left: 12px;

            .div-img {
              height: 45px;
              width: 45px;
              margin-left: 14px;
              margin-right: 22px;

              img {
                width: 20px;
                height: 20px;
                align-self: center;
              }

              .img-stye {
                height: 20px;
                width: 20px;
              }
            }

            .box-shadow {
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            }
          }

          .s-Card-mini:hover>.div-img {
            box-shadow: 0px 2px 2px rgba(229, 50, 45, 0.25);
          }
        }
      }
    }
  }
}