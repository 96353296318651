@import "../../colors.scss";
.Button-styles {
  border-style: solid;
  border-width: 1.5px;
  border-radius: 30px;
  width: max-content;
  padding: 12px 22px;
  font-family: "Lato";
  font-size: 400;
  text-transform: uppercase;
  display: flex;
  font-size: 16px;
  background-color: $white;
  .right-arrow {
    width: 22px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 480px) {
  .Button-styles {
    font-size: 15px;
    line-height: 18px;
    padding-top: 13px;
    border-width: 1px;
    .right-arrow {
      width: 18px;
      height: 18px;
    }
  }
}
@media screen and (min-width: 1919px)  {
  .Button-styles {
    font-size: 25px;
    line-height: 30px;
    padding: 25px 49px;
    border-radius: 600px;
    .right-arrow {
      width: 40px;
      height: 30px;
    }
  }
}
